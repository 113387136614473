<template>
    <div :style="`min-height: ${windowHeight}`" id="Recharge">
        <img class="title_img" src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210309143026316.png" alt="">
        <div class="container">
            <div class="tips_box">
                <div class="tips_1">当前页面仅限于本次促销活动购买美妆预存订货券，</div>
                <div class="tips_2">且预存订货券不支持提现和退款，请谨慎购买！</div>
            </div>
            <div class="coupon_box">
                <div class="coupon_line1">
                    <div class="money">520<span class="yuan">元</span></div>
                    <div class="coupon_handle_box">
                        <img v-if="coupon_num > 0" @click="changeCouponNum('reduce')"
                             src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210309151048956.png" alt="">
                        <span class="num">{{coupon_num}}</span>
                        <img @click="changeCouponNum('plus')"
                             src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210309151112832.png" alt="">
                    </div>
                </div>
                <div class="coupon_line2">美妆预存订货券</div>
            </div>
            <div class="pay_title">选择支付方式</div>
            <div @click="changePayType(1)" class="choose_item">
                <div class="choose_item_title">
                    <img class="pay_icon" src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210309151903787.png"
                         alt="">
                    <span>微信支付</span>
                </div>
                <img v-if="pay_type == 1" class="focus_circle"
                     src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210309153708157.png" alt="">
                <div v-else class="no_focus"></div>
            </div>
            <div class="line_"></div>
            <div @click="changePayType(2)" class="choose_item alipay">
                <div class="choose_item_title">
                    <img class="pay_icon" src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210309152131903.png"
                         alt="">
                    <span>支付宝支付</span>
                </div>
                <img v-if="pay_type == 2" class="focus_circle"
                     src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210309153708157.png" alt="">
                <div v-else class="no_focus"></div>
            </div>
        </div>
        <div class="handle_box">
            <div class="tips">注：采购券不支持提现和退款，请谨慎购买</div>
            <div class="buy_box">
                <div class="money_box">购买金额：<span class="money_icon">¥</span><span
                        class="num">{{520 * coupon_num}}</span></div>
                <div @click="sure" class="buy_btn">立即购买</div>
            </div>
        </div>
        <div v-if="loading || showModal" class="layer"
             style="position: fixed;z-index: 9;background-color: rgba(0,0,0,.3);height: 100%;width: 100%;left: 0;top: 0"></div>
        <div v-if="showModal" class="sure_layer">
            <div class="title">提示</div>
            <div class="layer_content">您当前购买的是<span class="focus_words">美妆</span>品类预存订货券，仅支持<span class="focus_words">梵洁诗/香蜜</span>品牌订货时使用，且订货券均不支持提现和退款哦！
            </div>
            <div class="btns">
                <div @click="showModal = false" class="layer_btn cancel_btn">取消</div>
                <div @click="buy" class="layer_btn sure_btn">确认购买</div>
            </div>
        </div>
    </div>
</template>

<script>
    var wx = require('weixin-js-sdk');
    export default {
        name: "Recharge",
        data() {
            return {
                windowHeight: '',
                pay_type: 1,
                coupon_num: 0,
                showModal: false,
                loading: false
            }
        },
        created() {
            document.title = '购采购券'
            this.windowHeight = window.innerHeight + 'px'
            // this.initWX()
        },
        methods: {
            initWX(data) {
                var that = this
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: data.appId, // 必填，公众号的唯一标识
                    timestamp: data.timeStamp, // 必填，生成签名的时间戳
                    nonceStr: data.nonceStr, // 必填，生成签名的随机串
                    signature: data.paySign,// 必填，签名
                    jsApiList: ['chooseWXPay'] // 必填，需要使用的JS接口列表s
                });
                wx.ready(function () {
                    wx.chooseWXPay({
                        appId: data.appId,
                        timestamp: data.timeStamp,
                        nonceStr: data.nonceStr,
                        package: data.package,
                        signType: data.signType,
                        paySign: data.paySign,
                        success: function (res) {
                            console.log(res)
                            that.$toast.clear()
                            this.loading = false
                            if (res.errMsg == "chooseWXPay:ok") {
                                that.$toast('充值成功');
                            } else if (res.errMsg == "get_brand_wcpay_request:cancel") {
                                that.$toast('取消充值');
                            } else {
                                that.$toast('充值失败');
                            }
                        },
                        fail: function(e) {
                            that.loading = false
                            that.$toast.clear()
                            that.$toast('充值失败');
                        },
                        cancel: function(e) {
                            that.loading = false
                            that.$toast.clear()
                            that.$toast('取消充值');
                        },
                        complete() {
                            that.loading = false
                            that.$toast.clear()
                        }
                    });
                })
            },
            changePayType(e) {
                this.pay_type = e
            },
            changeCouponNum(e) {
                switch (e) {
                    case 'reduce':
                        this.coupon_num --
                        break;
                    case 'plus':
                        this.coupon_num++
                        break;
                    default:
                        break;
                }
            },
            buy() {
                if (this.loading) return false
                this.loading = true
                this.showModal = false
                this.$toast.loading({message: '加载中..', duration: 0})
                this.post('/OnlyOnce/CouponsOrder/CreateCouponsOrder', {
                    buyCount: this.coupon_num,
                    payType: this.pay_type
                }).then(json => {
                    console.log(json)
                    let that = this
                    let data = json.response
                    if (json.code == 0) {
                        that.$toast.clear()
                        that.loading = false
                        return that.$dialog.alert({title: '提示', message: json.msg})
                    }
                    if (this.pay_type == 1) { //微信支付
                        that.initWX(data)
                    } else {
                        that.$toast.clear()
                        that.loading = false
                        that.$router.push(`/ALiPay/ALiPay/${data.orderId}`)
                    }
                })
            },
            sure() {
                if (this.coupon_num == 0) return  this.$toast('请选择预存订货券')
                this.showModal = true
            }
        }
    }
</script>

<style scoped lang="scss">
    #Recharge {
        background-image: url("http://freesaasofficedata.oss.aliyuncs.com/miShop/20210308102512067.png");
        background-repeat: no-repeat;
        background-size: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 100px;

        .title_img {
            width: 80%;
        }

        .sure_layer {
            position: fixed;
            z-index: 99;
            width: 75%;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            border-radius: 15px;
            background-color: #fff;
            padding: 15px 15px 25px 15px;

            .layer_content {
                margin-top: 10px;
                font-size: 14px;
                color: #333;

                .focus_words {
                    color: #ff98a0;
                }
            }

            .btns {
                margin-top: 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                box-sizing: border-box;
                /*padding-left: 20px;*/
                /*padding-right: 20px;*/

                .layer_btn {
                    padding: 5px 0px;
                    width: 130px;
                    border-radius: 500px;
                    box-sizing: border-box;
                    font-size: 16px;
                    text-align: center;
                }

                .cancel_btn {
                    color: #999;
                    border: 1px solid #e5e5e5;
                }

                .sure_btn {
                    background-color: #ff98a0;
                    color: #fff;
                }
            }

            .title {
                font-size: 16px;
                color: #666;
                font-weight: bold;
            }
        }

        .container {
            width: 92%;
            border-radius: 15px 15px 0 0;
            background: #fff;
            flex: 1;

            .pay_title {
                margin-left: 20px;
                margin-top: 15px;
                color: #666;
                font-size: 15px;
                font-weight: bold;
            }

            .line_ {
                height: 1px;
                background-color: #f1f1f1;
                width: 60%;
                margin-top: 10px;
                margin-right: 20px;
                margin-left: 110px;
            }

            .choose_item {
                display: flex;
                margin-top: 10px;
                align-items: center;
                justify-content: space-between;
                margin-left: 20px;
                margin-right: 20px;

                .no_focus {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    border: 1px solid #999;
                    box-sizing: border-box;
                }

                .focus_circle {
                    width: 20px;
                    height: 20px;
                }

                .choose_item_title {
                    display: flex;
                    align-items: center;
                    color: #666;
                    font-size: 14px;

                    .pay_icon {
                        width: 26px;
                        height: 25px;
                        margin-right: 10px;
                    }
                }
            }

            .coupon_box {
                margin-top: 20px;
                margin-left: 20px;
                margin-right: 20px;
                box-sizing: border-box;
                padding: 25px;
                background-image: url("http://freesaasofficedata.oss.aliyuncs.com/miShop/20210309150626696.png");
                background-size: 100%;
                background-repeat: no-repeat;

                .coupon_line1 {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .coupon_handle_box {
                        display: flex;
                        align-items: center;

                        .num {
                            margin-left: 5px;
                            margin-right: 5px;
                            font-size: 16px;
                            display: inline-block;
                            min-width: 20px;
                            text-align: center;
                        }

                        img {
                            width: 22px;
                            height: 22px;
                        }
                    }

                    .money {
                        font-size: 34px;
                        font-weight: bold;
                        color: #c40025;
                    }

                    .yuan {
                        position: relative;
                        bottom: 2px;
                        font-size: 18px;
                        font-weight: bold;
                        margin-left: 5px;
                    }
                }

                .coupon_line2 {
                    font-size: 14px;
                    font-weight: bold;
                }
            }

            .tips_box {
                background-image: url("http://freesaasofficedata.oss.aliyuncs.com/miShop/20210309135808164.png");
                background-repeat: no-repeat;
                background-size: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-top: 8px;
                padding-bottom: 8px;
                color: #ae434b;

                .tips_1 {
                    font-size: 14px;
                    font-weight: bold;
                    margin-bottom: 5px;
                }
            }
        }

        .handle_box {
            position: fixed;
            bottom: 0;
            left: 0;
            background-color: #fff;
            z-index: 1;
            text-align: start;
            width: 100%;

            .tips {
                background-color: #fff5f6;
                box-sizing: border-box;
                padding-left: 15px;
                padding-top: 8px;
                padding-bottom: 8px;
                color: #c40025;
            }

            .buy_box {
                box-sizing: border-box;
                padding-left: 15px;
                padding-right: 20px;
                padding-top: 10px;
                padding-bottom: 30px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .buy_btn {
                    padding: 6px 18px 6px 18px;
                    background: linear-gradient(to right, #e30025, #b50022);
                    color: #fff;
                    border-radius: 500px;
                    font-size: 14px;
                    font-weight: bold;
                }

                .money_box {
                    color: #b4b4b4;
                    font-size: 14px;

                    .num {
                        color: #c40025;
                        font-size: 22px;
                        margin-left: 2px;
                    }

                    .money_icon {
                        color: #c40025;
                    }
                }
            }
        }
    }
</style>